.map-panel {
  box-shadow: inset rgba(0, 0, 0, 1);
  height: 100%;
  margin-top: 1em;
  margin-inline: 0.5em;
  overflow-x: hidden;
  overflow-y: scroll;
}

.model-button {
  width: 60px;
  height: 60px;
  min-width: 60px;
  margin: 5px;
}

.model-button .MuiButton-root {
  -webkit-backdrop-filter: blur(13.3px);
  backdrop-filter: blur(13.3px);
  background-color: rgba(40, 39, 39, 1);
  border-radius: 16px;
  border: 1px solid rgba(40, 39, 39, 0.65);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}
.model-button:hover{
  background-color: rgba(var(--secondary-color-raw), 0.3);
  /* border: 1px solid var(--link-color); */
}
.model-button > .MuiButton-outlinedPrimary {
  border-width: 5px;
  /* background-color: var(--primary-color); */
}

.terrain-type__root {
  display: grid;
  grid-template-rows: 1fr 20px;
  grid-template-columns: 80px 1fr 80px;
}
.terrain-type__slider {
  margin-inline: 15px;
  width: auto;
}
.terrain-type__terrain-icon {
  width: 30px;
  height: 30px;
  translate: 0px -10px
}
.terrain-type__terrain-icon > img {
  width: inherit;
  object-fit: cover;
  filter: invert(40%);
}
.terrain-type__terrain-icon  > img:hover:not([aria-disabled="true"]) {
  filter: invert(60%);
}
.terrain-type__terrain-icon  > img[aria-disabled="true"] {
  filter: invert(35%);
}



/*https://css-tricks.com/how-to-recreate-the-ripple-effect-of-material-design-buttons/*/
span.ripple {
  animation: ripple 600ms linear;
  background-color: rgba(0, 255, 255, 0.7);
  border-radius: 50%;
  height: 100px;
  margin-left: -50px;
  margin-top: -50px;
  position: absolute; /* The absolute position we mentioned earlier */
  transform: scale(0);
  width: 100px;
}
@keyframes ripple {
  to {
    opacity: 0;
    transform: scale(4);
  }
}

/*Attribute inputs*/
.MuiSlider-colorPrimary {
  color: var(--primary-color);
}

.circle-slider {
  display: block;
  justify-self: center;
  flex-wrap: nowrap;
  translate: 0px -20px;
  height: 45px;
}
.circle-slider__center-icon {
  color: var(--primary-color);
  position: absolute;
  transform: translate(12px, 12px);
  z-index: 3;
}
.circle-slider__center-icon[aria-disabled="true"] {
  color: var(--secondary-color);
}
.circle-slider__knob-icon {
  color: var(--bg-color);
  opacity: 0.85;
}

/* Link buttons */
.link-button {
  align-self: flex-end;
  color: var(--secondary-color);
  width: 22px;
  height: 22px;
  display: flex;
}
.link-button[aria-disabled="true"] {
  color: var(--secondary-color) !important;
}
.rotate-link {
  animation: link-rotate 0.5s ease-out forwards;
}
.rotate-link-reverse {
  animation: link-rotate-reverse 0.5s ease-out;
}
@keyframes link-rotate {
  0% {
    transform: rotate(0deg);
    color: var(--secondary-color);
  }
  100% {
    transform: rotate(90deg);
    color: var(--primary-color);
  }
}
@keyframes link-rotate-reverse {
  0% {
    color: var(--primary-color);
    transform: rotate(90deg);
  }
  100% {
    color: var(--secondary-color);
    transform: rotate(0deg);
  }
}


.attribute-box {
  align-items: center;
  display: inline-flex;
  width: 100%;
  /* Hide spinner for Firefox when not hovered */
  input[type=number] {
    -moz-appearance: textfield;
  }
  input[type=number]:hover,
  input[type=number]:focus {
      -moz-appearance: auto;
  }
}
.attribute-box > * {
  margin-inline: 0.5rem;
}
.attribute-box > *:last-child {
  margin-right: 0;
}
.attribute-box > .MuiSlider-root:first-child {
  margin-inline-start: 0.5em;
}

.attribute-header {
  display: inline-flex;
  justify-content: space-between;
  text-align: center;
}

.attribute-box > .MuiTypography-root {
  font-size: 14px;
  text-align: right;
  width: 30%;
  min-width: 55px;
}
.attribute-box > .MuiSlider-root {
  width: clamp(55%, 70%, 80%);
}
.attribute-box > .MuiSlider-root > .MuiSlider-thumb {
  cursor: grab;
  /* pointer-events: auto; */
}
.attribute-box > .MuiSlider-root > .MuiSlider-thumb:active {
  cursor: grabbing;
}

.attribute-box > .input-box {
  display: block;
  width: 80px;
}