  .page-layout {
  }
  
  .pay-button {
    padding: 10px 20px;
    background-color: #44b81d;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .pay-button:hover {
    background-color: #2e7e13;
  }

  .yes-button {
    background-color: #4CAF50;
    color: white;
    border-radius: 8px;
    padding: 10px 15px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .yes-button:hover {
    background-color: #45a049;
  }
  
  .no-button {
    background-color: #f44336;
    color: white;
    border-radius: 8px;
    padding: 10px 15px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .no-button:hover {
    background-color: #da190b;
  }

  .close-button {
    padding: 10px 20px;
    background-color: #dbdbdb;
    color: black;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }

  .close-button:hover {
    background-color: #cfcece;
  }

  .inputContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .inputBox {
      position: relative;
      width: 250px;
  }

  .inputBox input {
      text-align: center;
      width: 100%;
      padding: 10px;
      border: 1px solid rgba(255, 255, 255, 0.25);
      background: #2c2c2e;
      border-radius: 5px;
      outline: none;
      color:#fff;
      font-size: 1em;
  }

  .inputBox span {
    position: absolute;
    left: 0;
    padding: 10px;
    pointer-events: none;
    font-size: 1em;
    color: rgba(255, 255, 255, 0.25);
    text-transform: uppercase;
    transition: 0.5s;
  }

  .inputBox input:valid ~ span,
  .inputBox input:focus ~ span
{
    color: white;
    transform: translateX(25px) translateY(-7px);
    font-size: 0.65em;
    padding: 0 10px;
    background: #2c2c2e;
    background: #2c2c2e;
    border: 1px solid black; 
    border-radius: 5px;
    letter-spacing: 0.2em;
}

.conversionBox {
    width: 250px;
    height: 45px;
    padding: 10px;
    margin: 10px;
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-radius: 5px;
    font-size: 16px;
    background: #2c2c2e;
    color: #fff;
    display: flex;
  }

  .conversionBox span {
    flex: 1;
    text-align: center;
    border-right: 1px solid rgba(255, 255, 255, 0.25); /* Add a border to separate the halves */
  }

  .conversionBox div {
    flex: 1;
    text-align: center;
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    color: #fff;
    font-size: 16px;
    text-align: center;
  }

  .popup {
    width: 400px;
    background: #fff;
    border-radius: 6px;
    text-align: center;
    padding: 25px 30px 30px;
    color: #333;
    z-index: 1000; 
}

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
}


