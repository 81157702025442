.dropzone, .dropzone > * {
    color: var(--primary-text);
}
.dropzone > svg {
    color: var(--primary-text);
}

.leaflet-container {
    width: 100%;
    height: 100%;
}

.image-zoom-container {
    /* Leaflet container puts a white background for some reason*/
    background-color: transparent !important;
    z-index: 0;
}